import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const StyledWrapper = styled.div`
  padding: 16px 0 56px;
`

const OrganizationalChart = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "about/organizational-chart.png" }) {
        childImageSharp {
          fluid(maxWidth: 1358, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledWrapper>
      <Img fluid={fluid} alt="Frameway group structure." />
    </StyledWrapper>
  )
}

export default OrganizationalChart
